<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单</el-breadcrumb-item>
            <el-breadcrumb-item>发货</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form ref="form" :model="form" label-width="140px">
        <el-form-item>
          <el-descriptions class="margin-top" title="收件人信息" border="">
            <el-descriptions-item>
              <template slot="label">姓名</template>
              {{ orderinfo.collectname }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">手机号</template>
              {{ orderinfo.collectphone }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">收货地址</template>
              {{ orderinfo.collectprovince }}- {{ orderinfo.collectcity }}- {{ orderinfo.collectarea }}-
              {{ orderinfo.collectaddress }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">缺货备注</template>
              {{ orderinfo.shortageremarks }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">用户备注</template>
              {{ orderinfo.userremarks }}
            </el-descriptions-item>
          </el-descriptions>
        </el-form-item>

        <el-form-item>
          <el-descriptions class="margin-top" column="3" title="发货信息" border="">
            <el-descriptions-item>
              <template slot="label">快递公司</template>
              {{ orderinfo.expressName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">快递公司代码</template>
              {{ orderinfo.expressCode }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">物流单号</template>
              {{ orderinfo.expressnum }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">发货时间</template>
              {{ orderinfo.delivertime }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">快递100回执编号</template>
              {{ orderinfo.taskId }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">打印地址</template>
              <el-link type="primary" target="_blank" :href="orderinfo.templateurl">{{ orderinfo.templateurl }}
              </el-link>
            </el-descriptions-item>
          </el-descriptions>
        </el-form-item>
        <el-form-item label="商品明细">
          <el-table size="mini" :data="orderinfo.orderitem">
            <el-table-column label="商品略图" width="110" align="center">
              <template slot-scope="scope">
                <el-image :src="scope.row.productimg" alt="图片">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column property="productname" label="商品名称"></el-table-column>
            <el-table-column property="skuname" label="规格名称"></el-table-column>
            <el-table-column property="productprice" label="单价">
              <template slot-scope="scope">
                {{ scope.row.productprice }}
                <el-tag v-if="scope.row.productunit"> /{{ scope.row.productunit }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column property="productnum" label="购买数量"></el-table-column>
            <el-table-column property="skuweight" label="重量"></el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="打印类型">
          <el-radio-group v-model="form.expressPrinttype">
            <el-radio :label="1">快递100</el-radio>
            <el-radio :label="2">第三方</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="快递公司">
          <el-select v-model="form.expressid" filterable allow-create default-first-option placeholder="请选择"
            @change="changeexpress">
            <el-option v-for="item in expressdata" :key="item.id" :label="item.exname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号" v-if="form.expressPrinttype == 2">
          <el-input v-model="form.expressnum"></el-input>使用第三方快递发货，填写快递单号，不会请求快递100打印面单
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="createAd">发货</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { editRequest, getselectExpress } from '@/api/sys_Expressdelivery'
import { getdatilRequest, deliverorder } from '@/api/fj_order'
import ImageUpload from '@/components/imageUpload'
import { required } from 'vuelidate/lib/validators'
import utils from '@/utils/util'
export default {
  typeexcode: 'Articletypecreate',
  data() {
    return {
      tabHeight: window.innerHeight - 300,
      classify: [],
      form: { orderno: '', expressid: '', expressnum: '', expressPrinttype: 1 },
      orderinfo: {},
      expressdata: []
    }
  },
  components: {
    'image-upload': ImageUpload
  },
  created() {
    this.form.orderno = this.$route.query['id']
    this.getselectExpress()
    this.getdatil()
  },
  methods: {
    back() {
      this.$router.back()
    },
    changeexpress(e) {
      console.info(e)
    },
    getdatil() {
      getdatilRequest({
        orderno: this.form.orderno
      }).then(res => {
        this.orderinfo = res.data
      })
    },
    getselectExpress() {
      getselectExpress().then(res => {
        console.log(res)
        this.expressdata = res.data
      })
    },
    createAd() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form))
        deliverorder(params).then(res => {
          if (res.code == 1) {
            this.$message(res.msg)
            this.getdatil()
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    }
  },
  validations: {
    form: {}
  }
}
</script>

<style lang="less" scoped>

</style>
