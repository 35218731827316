import request from '@/utils/request'
const headers = {
  'Content-Type': 'application/xls'
}
// 销售金额导出
export function getproducttjlistexprot(params) {
  return request({
    url: '/admin/fj_order/getproducttjlistexprot',
    methos: 'get',
    headers,
    responseType: 'blob',
    params
  })
}// 区域统计
export function getproductarealistexprot(params) {
  return request({
    url: '/admin/fj_order/getproductarealistexprot',
    methos: 'get',
    headers,
    responseType: 'blob',
    params
  })
}
// 礼品统计
export function getproducttjlistexprotgift(params) {
  return request({
    url: '/admin/fj_order/getproducttjlistexprotgift',
    methos: 'get',
    headers,
    responseType: 'blob',
    params
  })
}




// 订单导出
export function exportOrderRequest(params) {
  return request({
    url: '/admin/fj_order/exportOrder',
    methos: 'get',
    headers,
    responseType: 'blob',
    params
  })
}

// 分页列表
export function getListRequest(params) {
  return request({
    url: '/admin/fj_order/getList',
    method: 'get',
    params
  })
}

// 分页列表
export function getbagList(params) {
  return request({
    url: '/admin/fj_orderbag/getList',
    method: 'get',
    params
  })
}

// 添加 
export function addtypeRequest(data) {
  return request({
    url: '/admin/fj_order/add',
    method: 'post',
    data
  })
}


// 获取 详情
export function getdatilRequest(params) {
  return request({
    url: '/admin/fj_order/getdatil',
    method: 'get',
    params
  })
}
// 打印小票
export function printorder(params) {
  return request({
    url: '/admin/fj_order/printorder',
    methos: 'get',
    params
  })
}
// 删除 
export function delRequest(params) {
  return request({
    url: '/admin/fj_order/del',
    method: 'get',
    params
  })
}
// 退款 
export function refundorderRequest(params) {
  return request({
    url: '/admin/fj_order/refundorder',
    method: 'get',
    params
  })
}



// 修改 
export function editRequest(data) {
  return request({
    url: '/admin/fj_order/edit',
    method: 'post',
    data
  })
}
// 修改状态
export function setpayRequest(params) {
  return request({
    url: '/admin/fj_order/setpay',
    method: 'get',
    params
  })
}


// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/fj_order/setStates',
    method: 'get',
    params
  })
}

// 修改排序
export function setSortRequest(params) {
  return request({
    url: '/admin/fj_order/setSort',
    method: 'get',
    params
  })
}

// 修改排序
export function tberp(params) {
  return request({
    url: '/admin/fj_order/tberp',
    method: 'get',
    params
  })
}

// 修改排序
export function ljerp(params) {
  return request({
    url: '/admin/fj_order/ljerp',
    method: 'get',
    params
  })
}

// 销售金额
export function getproducttjlist(params) {
  return request({
    url: '/admin/home/getproducttjlist',
    method: 'get',
    params
  })
}

// 销售金额
export function getproductarealist(params) {
  return request({
    url: '/admin/home/getproductarealist',
    method: 'get',
    params
  })
}

// 物流发货
export function deliverorder(params) {
  return request({
    url: '/admin/fj_order/deliverorder',
    method: 'get',
    params
  })
}
// 修改訂單支付金額
export function editordermoney(params) {
  return request({
    url: '/admin/fj_order/editordermoney',
    method: 'get',
    params
  })
}
// 分批次退款
export function refundorderbatch(params) {
  return request({
    url: '/admin/fj_order/refundorderbatch',
    method: 'get',
    params
  })
}// 批量获取面单
export function getexpressmd(params) {
  return request({
    url: '/admin/fj_order/getexpressmd',
    method: 'get',
    params
  })
}
// 批量发货
export function getBatchshipment(params) {
  return request({
    url: '/admin/fj_order/getBatchshipment',
    method: 'get',
    params
  })
}


