import request from '@/utils/request'

// 分页列表
export function getListRequest(params) {
  return request({
    url: '/admin/sys_Expressdelivery/getList',
    method: 'get',
    params
  })
}


// 修改 
export function editRequest(data) {
  return request({
    url: '/admin/sys_Expressdelivery/edit',
    method: 'post',
    data
  })
}


// 获取 详情
export function getdatilRequest(params) {
  return request({
    url: '/admin/sys_Expressdelivery/getdatil',
    method: 'get',
    params
  })
}

// 删除 
export function delRequest(params) {
  return request({
    url: '/admin/sys_Expressdelivery/del',
    method: 'get',
    params
  })
}




// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/sys_Expressdelivery/setStates',
    method: 'get',
    params
  })
}


// 修改排序
export function setSortRequest(params) {
  return request({
    url: '/admin/sys_Expressdelivery/setSort',
    method: 'get',
    params
  })
}

// 下拉框
export function getselectExpress(params) {
  return request({
    url: '/admin/sys_Expressdelivery/getselectExpress',
    method: 'get',
    params
  })
}
